<template>
  <section>
    <vue-good-table
      v-if="columns.length > 0"
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="isRtl"
      :search-options="{
        enabled: false,
      }"
      :select-options="{ 
        enabled: isParticipantPortal ? false : true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'bulk-selection-wrapper',
        selectionText: 'Participants selected',
        clearSelectionText: 'Clear Selections',
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
    
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-selected-rows-change="bulkSelectionChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'currentStatus'">
          <b-badge :variant="STATUS_COLOR[props.row.user_roles[props.row.user_roles.length - 1].user_status.name]">
            {{ props.row.user_roles[props.row.user_roles.length - 1].user_status.name }}
          </b-badge>
        </span>
        <!-- Column: Action -->
        <div
          v-else-if="props.column.field === 'action' && !isParticipantPortal"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap"
        >
          <div class="align-items-center mb-0 mt-0">
            <b-button
              variant="outline-none"
              class="nav action-menu pr-0"
            >
              <b-nav-item-dropdown
                class="btn-icon"
                :ref="`actionsDropdown_${props.row.id}`"
                @shown="moveDropdownToBody(`actionsDropdown_${props.row.id}`)"
                dropleft
              >
                <template 
                  #button-content
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />                
                </template>

                <action-dropdown-item
                  link-class="d-flex align-items-center"
                  @click="editParticipant(props.row.id)"
                >
                  <feather-icon
                    size="16"
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </action-dropdown-item>

                <action-dropdown-item
                  link-class="d-flex align-items-center"
                  :disabled="!isUserActive(props.row)"
                  @click.prevent="sendMessage(props.row.id)"
                >
                  <feather-icon
                    size="16"
                    icon="MessageSquareIcon"
                    class="mr-50"
                  />
                  <span>Message</span>
                </action-dropdown-item>
                <resend-access
                  :user="props.row"
                  :user-id="props.row.id"
                  @updated="loadItems"
                />
                <action-dropdown-item
                  v-if="isUserActive(props.row)"
                  link-class="d-flex align-items-center"
                  @click.prevent="loginAs(props.row.id)"
                >
                  <feather-icon
                    size="16"
                    icon="UserIcon"
                    class="mr-50"
                  />
                  <span>Login as</span>
                </action-dropdown-item>
                <action-dropdown-item
                  link-class="d-flex align-items-center"
                  @click.prevent="activeUser(props.row)"
                >
                  <feather-icon
                    size="16"
                    icon="SmileIcon"
                    class="mr-50"
                  />
                  <span>Activate</span>
                </action-dropdown-item>
                <!-- Suspend User -->
                <suspend-user
                  :user="props.row"
                  :user-id="props.row.id"
                  @updated="loadItems"
                />
              </b-nav-item-dropdown>
            </b-button>
            <remove-user
              as-button
              :user="props.row"
              :user-id="props.row.id"
              @updated="loadItems"
            />
          </div>
        </div>
        <div
          v-else-if="props.column.field === 'full_name'"
          cols="12"
          md="2"
          class="justify-content-between flex-wrap"
        >
          <div class="align-items-start mb-0 mt-0">
            <router-link
              :to="{
                name: 'champion-program-participant',
                params: { id: defaultProgramId, participantId: props.row.id },
              }"
              class="table-primary-link"
            >
              {{ props.row.full_name }}
            </router-link>
          </div>
        </div>
        <div v-else-if="props.column.field === 'email_notification_status'">
          <action-checkbox
            :checked="props.row.email_notification_status"
            disabled-tooltip
            switch
            inline
            @clickNativePrevent="toggleEmailNotificationStatus(props.row, !props.row.email_notification_status)"
          />
        </div>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
      <div slot="selected-row-actions">
        <action-dropdown
          text="Selected Actions"
          variant="primary"
        >
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkSendMessage"
          >
            <feather-icon
              size="16"
              icon="MessageSquareIcon"
              class="mr-50"
            />
            <span>Send Message</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="showFormGroupModal()"
          >
            <feather-icon
              size="16"
              icon="UsersIcon"
              class="mr-50"
            />
            <span>Form Group</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkResendAccess"
          >
            <feather-icon
              size="16"
              icon="MailIcon"
              class="mr-50"
            />
            <span>Resend Access</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkEnableNotifications(true)"
          >
            <feather-icon
              size="16"
              icon="BellIcon"
              class="mr-50"
            />
            <span>Enable Notifications</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkEnableNotifications(false)"
          >
            <feather-icon
              size="16"
              icon="BellOffIcon"
              class="mr-50"
            />
            <span>Disable Notifications</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkActive"
          >
            <feather-icon
              size="16"
              icon="UserCheckIcon"
              class="mr-50"
            />
            <span>Activate</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="showBulkSuspendModal"
          >
            <feather-icon
              size="16"
              icon="UserMinusIcon"
              class="mr-50"
            />
            <span>Suspend</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkUnSuspend"
          >
            <feather-icon
              size="16"
              icon="UserCheckIcon"
              class="mr-50"
            />
            <span>Unsuspend</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="bulkRemoveUsers"
          >
            <feather-icon
              size="16"
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Remove</span>
          </b-dropdown-item>
        </action-dropdown>
      </div>
    </vue-good-table>
    <form-group-modal
      v-if="!isParticipantPortal"
      :show="showFormGroup"
      :users="bulkSelection"
      @close="closeFormGroup"
    />
    <bulk-suspend-users
      v-if="!isParticipantPortal"
      :show="showBulkSuspend"
      :users="bulkSelection"
      @close="closeBulkSuspend"/>
    <aom-delete-confirmation-modal ref="delete-confirmation-modal" />
  </section>
</template>

<script>
import ActionCheckbox from "@/views/components/common/ActionCheckbox.vue";
import ActionDropdown from "@/views/components/common/ActionDropdown.vue";
import { BButton, BBadge, BDropdownItem, BDropdown, BNavItemDropdown, BFormCheckbox} from "bootstrap-vue";
import { 
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT, 
  GOODTABLE_LOCALISED_DATE_FORMAT, 
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
  PRIMARY_DATE_FORMAT, 
  localDateStringOrDateRangeStringToUtcRange 
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { userRolesDisplay, allParticipantRolesDisplay, userRoles as userRolesModel } from '@models/userRoles';
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import usersService  from "@/services/usersService";
import { mapGetters } from 'vuex';
import { STATUS_COLOR } from "@/libs/utils";
import { programTypes, userStatus, userStatusSelect } from "@models";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import SuspendUser  from './actions/SuspendUser';
import BulkSuspendUsers  from './actions/BulkSuspendUsers';
import RemoveUser from './actions/RemoveUser';
import ResendAccess from './actions/ResendAccess';
import FormGroupModal from './actions/FormGroupModal';
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import { authService } from "@/services";
import { AOM_MAIN_DOMAIN, AOM_PORTAL_SUB_DOMAIN } from '@/constants/app';
import AomDeleteConfirmationModal from "@aom-core/AomDeleteConfirmationModal.vue";
import { roleAlias } from "@/@aom-core/utils/utils";
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import { mentoringProgramParticipantColumns, trainingProgramParticipantColumns } from "@/models/enums/programParticipantList";

export default {
  components: {
    ActionDropdownItem,
    ActionCheckbox,
    BButton,
    BDropdown,
    BDropdownItem,
    BNavItemDropdown,
    VueGoodTable,
    TablePagination,
    BBadge,
    SuspendUser,
    RemoveUser,
    ResendAccess,
    FormGroupModal,
    AomDeleteConfirmationModal,
    BFormCheckbox,
    ActionDropdown,
    BulkSuspendUsers
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      bulkSelection: [],
      rows: [],
      columnFilters: [],
      sort: [],
      showFormGroup: false,
      showBulkSuspend: false
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    ...mapGetters("app", ["isParticipantPortal"]),

    isRtl() {
      return store.state.appConfig.isRTL;
    },
    isProgramTypeTraining() {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
    columns() {
      return this.isProgramTypeTraining ? trainingProgramParticipantColumns : mentoringProgramParticipantColumns;
    }
  },
  watch: {
    columns(n){
      if(n){
        this.loadItems();
      } 
    }
  },
  created() { 
    if (this.$route.params.filter) {
      const filter = this.$route.params.filter;
      const fieldIndex = this.columns.findIndex(c => c.field === filter.field);
      this.columns[fieldIndex].filterOptions.filterValue = filter.value;
    }
  },
  mounted() {
    // Allows target by name prefix is vgt-{field-name}
    const createdAtColumn = document.getElementsByName("vgt-created_at")[0];
      flatPickr(createdAtColumn, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });

  },
  methods: {
    activeUser(user) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to make this user active?`, {
          title: `Activate Participant`,
          size: 'sm',
          okVariant: 'primary',
          okTitle: `Ok`,
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              const data = {
                status_id: userStatus.ACTIVE,
                users: [ {id: user.id }]
              };
              const programId = this.$route.params.id;
              const result = await usersService.setManyParticipantStatus(programId, data);
              this.loadItems();
              if (result) {
                this.$toast(makeSuccessToast(`Participant activated`));
              }
            } catch (e) {
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            }
          }
        });
    },
    showFormGroupModal() {
      this.showFormGroup = true;
    },
    showBulkSuspendModal() {
      this.showBulkSuspend = true;
    },
    closeFormGroup() {
      this.showFormGroup = false;
    },
    closeBulkSuspend() {
      this.showBulkSuspend = false;
      this.loadItems();
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        if (field === 'currentStatus') {
          field = 'status';
        } else if (field === 'locked_and_active_matches_count'){
          field = 'locked_and_active_matches_count';
        }
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "currentStatus",
        "full_name",
        "email",
        "role",
        "locked_and_active_matches_count",
        "created_at"
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'role'){
            let roleIds = [];
            roleIds.push(params.columnFilters.role);
            columnFilters.push({
              field: col,
              value: roleIds
            });
          } else if (col === 'locked_and_active_matches_count') {
            columnFilters.push({
              field: col,
              value: Number(params.columnFilters[col])
            });
          } else if (col === "created_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else if (col === 'currentStatus') {
            columnFilters.push({
              field: 'status',
              value: Number(params.columnFilters['currentStatus'])
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await usersService.getListParticipantsByProgram(this.defaultProgramId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        }, true);
        const { items, total }  = response.data;
        this.total = total;
        const mappedItems = items.map(i => i = { 
          ...i,
          role: this.userRole(i?.user_roles),
          email_notification_status: i.user_program_settings.length
            ? i.user_program_settings[0].email_notifications
            : false,
        });
        this.rows = mappedItems;
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }
            
    },
    userRole(userRoles) {
      if(userRoles.length > 0) {
        const roles = userRoles.map(r => userRolesDisplay[r.role.id]);
        const uniqueRoles = [...new Set(roles)];

        // remove PARTICIPANT CANDIDATE role if MENTOR/MENTEE role is existed
        const hasParticipantRoles = uniqueRoles.findIndex(r => r === userRolesDisplay[userRolesModel.MENTOR] || r === userRolesDisplay[userRolesModel.MENTEE]);
        const hasParticipantCandidateRole = uniqueRoles.findIndex(r => r === userRolesDisplay[userRolesModel.PARTICIPANT_CANDIDATE]);
        
        if (hasParticipantRoles !== -1 && hasParticipantCandidateRole !== -1) {
          uniqueRoles.splice(hasParticipantCandidateRole, 1);
        }
        return uniqueRoles.map(r => {
          const index = Object.values(userRolesDisplay).findIndex(label => label === r);
          if (index > -1) {
            return roleAlias(Object.keys(userRolesDisplay)[index], this.defaultProgram);
          }
          return r;
        }).join(", ");
      }
      return '';
    },
    editParticipant(id) {
      this.$emit('editParticipant', id);
    },
    async bulkRemoveUsers() {
      let modalPrompt = '';
      const usersToDelete = this.bulkSelection.filter(s => {
        if(Number(s.locked_and_active_matches_count) === 0) {
          return {
            id: s.id
          };
        }
      });
      if(Number(usersToDelete.length) === 0 ) {
        this.$bvModal
          .msgBoxConfirm('You cannot remove a participant from a program if they have an active published match.', {
            title: 'Cannot Remove',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'OK',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              return;
            }
          });
      } else {
        if(usersToDelete.length < this.bulkSelection.length) {
          const usersNotDeleted = Number(this.bulkSelection.length) - Number(usersToDelete.length);
          modalPrompt = `${usersNotDeleted} of the selected participants have an active published match and cannot be removed. Would you like to remove the remaining ${usersToDelete.length}  participants?`;
        } else {
          modalPrompt = ` Warning: Deleting these users will remove access from all programs. Are you sure you want to continue?`;
        }

        const isConfirmed = await this.$refs['delete-confirmation-modal'].confirmDelete(
          modalPrompt,
          {
            title: `Remove ${usersToDelete.length} users`,
            okTitle: 'Remove'
          }
        );

        if (!isConfirmed) {
          return ;
        }

        try {
          const programId = this.$route.params.id;
          const usersToDelete = this.bulkSelection.filter(s => {
            if(Number(s.locked_and_active_matches_count) === 0) {
              return {
                id: s.id
              };
            }
          });
          const data = {
            users: usersToDelete
          };
          const result = await usersService.deleteManyParticipants(programId, data);
          if (result) {
            this.$toast(makeSuccessToast('Bulk Remove successful.'));
            this.loadItems();
          }
        } catch (e) {
          const { data } = e.response;
          this.$toast(makeErrorToast(data.message));
          this.$log.error(e);
        }
      }
     
    },
    bulkSelectionChange(args) {
      const { selectedRows } = args;
      if(Array.isArray(selectedRows)) {
        this.bulkSelection = selectedRows;
      }
    },
    bulkUnSuspend() {
      if(Array.isArray(this.bulkSelection) && this.bulkSelection.length > 0) {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to unsuspend these users? Inactive users will be ignored.', {
            title: `Unsuspend ${this.bulkSelection.length} users`,
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Unsuspend',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              try {
                const programId = this.$route.params.id;
                const usersToSuspend = this.bulkSelection.filter(user => {
                  return this.isUserSuspended(user);
                });
                if(usersToSuspend.length === 0) {
                  this.$toast(makeErrorToast('There are no users to Unsuspend.'));
                  return;
                }
                const data = {
                  status_id: userStatus.ACTIVE,
                  users: usersToSuspend
                };
                const result = await usersService.setManyParticipantStatus(programId, data);
                if (result) {
                  this.$toast(makeSuccessToast('Bulk Unsuspend successful.'));
                  this.loadItems();
                }
              } catch (e) {
                const { data } = e.response;
                this.$toast(makeErrorToast(data.message));
                this.$log.error(e);
              }
            }
          });
      }
    },
    bulkActive() {
      if(Array.isArray(this.bulkSelection) && this.bulkSelection.length > 0) {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to make these users active?', {
            title: `Active ${this.bulkSelection.length} users`,
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Active',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              try {
                const programId = this.$route.params.id;
                const usersToActive = this.bulkSelection;
                if(usersToActive.length === 0) {
                  this.$toast(makeErrorToast('There are no users to make active.'));
                  return;
                }
                const data = {
                  status_id: userStatus.ACTIVE,
                  users: usersToActive
                };
                const result = await usersService.setManyParticipantStatus(programId, data);
                if (result) {
                  this.$toast(makeSuccessToast('Bulk Activation successful.'));
                  this.loadItems();
                }
              } catch (e) {
                const { data } = e.response;
                this.$toast(makeErrorToast(data.message));
                this.$log.error(e);
              }
            }
          });
      }
    },
    bulkEnableNotifications(emailNotifications = false) {
      if(Array.isArray(this.bulkSelection) && this.bulkSelection.length > 0) {
        let modalPrompt = emailNotifications ?
          'Enabling notification will cause these participant to receive email notifications. Do you wish to continue?.':
          'Disabling notification will cause these participant to not receive email notifications. Do you wish to continue?';
        let modalTitle = emailNotifications ?
          'Enable Notifications?':
          'Disable Notifications?';
        let modalOkButton = emailNotifications ?
          'Enable':
          'Disable';
        let successMsg = emailNotifications ? 'Enable' : 'Disable';
        this.$bvModal
          .msgBoxConfirm(modalPrompt, {
            title: modalTitle,
            size: 'sm',
            okVariant: 'primary',
            okTitle: modalOkButton,
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              try {
                const programId = this.$route.params.id;
                const users = this.bulkSelection.map(s => ({id: s.id}));
                const data = {
                  email_notifications: Boolean(emailNotifications),
                  users: users
                };
                const result = await usersService.setManyParticipantProgramSettings(programId, data);
                if (result) {
                  this.$toast(makeSuccessToast(`Email Notifications for this program are ${successMsg} for all selected participants. The user will always still receive in platform notifications.`));
                  this.loadItems();
                }
              } catch (e) {
                const { data } = e.response;
                this.$toast(makeErrorToast(data.message));
                this.$log.error(e);
              }
            }
          });
      }
    },
    bulkResendAccess() {
      if(Array.isArray(this.bulkSelection) && this.bulkSelection.length > 0) {
        this.$bvModal
          .msgBoxConfirm(`Do you want to resend a link to ${this.bulkSelection.length} user’s email? This will allow them to reset their password. Nothing will happen if the link isn’t used.`, {
            title: `Resend Access to ${this.bulkSelection.length} users`,
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Resend Link',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if(value) {
              try {
                const programId = this.$route.params.id;
                const data = {
                  users: this.bulkSelection.map(s => ({id: s.id}))
                };
                const result = await usersService.sendManyParticipantPasswordReset(programId, data);
                if (result) {
                  this.$toast(makeSuccessToast('Password reset link has been sent to participants registered email!.'));
                  this.loadItems();
                }
              } catch (e) {
                const { data } = e.response;
                this.$toast(makeErrorToast(data.message));
                this.$log.error(e);
              }
            }
          });
      }
    },
    bulkSendMessage() {
      if(Array.isArray(this.bulkSelection) && this.bulkSelection.length > 0) {
        const users = this.bulkSelection.map(u => u.id);
        this.$router.push({name: 'champion-program-communications', query: {user_ids: users}});
      }
    },
    sendMessage(userId) {
      this.$router.push({name: 'champion-program-communications', query: {user_ids: userId}});
    },
    async loginAs(userId) {
      try {
        const result = await authService.makeloginAsUserToken(this.defaultProgramId, userId);
        const token = result.data.token;
        const protocol = window.location.protocol;
        const link = `${protocol}//${AOM_PORTAL_SUB_DOMAIN}.${AOM_MAIN_DOMAIN}/${this.defaultProgram.client.path}/${this.defaultProgram.path}/login?token=${token}`;
        window.open(link);
      } catch(e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
      }
    },
    filterUserRoles(roles) {
      return roles.filter(r => r.role_id !== userRolesModel.PARTICIPANT_CANDIDATE);
    },
    isUserActive(user) {
      return (
        Number(user.user_roles[user.user_roles.length - 1].status_id) ===
        Number(userStatus.ACTIVE)
      );
    },
    isUserInactive(user) {
      return user.user_roles.find(ur => ur.status_id === userStatus.INACTIVE) !== undefined;
    },
    isUserSuspended(user) {
      return user.user_roles.find(ur => ur.status_id === userStatus.SUSPENDED) !== undefined;
    },
    async toggleEmailNotificationStatus(user, status) {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const data = {
          ...user,
          user_roles: user.user_roles.filter(r => r.role_id === userRolesModel.MENTEE || r.role_id === userRolesModel.MENTOR),
          user_program_settings: [
            {
              program_id: programId,
              email_notifications: status
            }
          ]
        };
        const response = await usersService.patchUpdateProgramUser(programId, user.id, data);
        this.$toast(makeSuccessToast('User updated successfully.'));
        await this.loadItems();
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$toast(makeErrorToast(data.errors));
        } else if (status === 400 && data.message) {
          this.$toast(makeErrorToast(data.message));
        } else {
          this.$toast(makeErrorToast('Something went wrong !. User not updated.'));
        }
      } finally {
        this.isLoading = false;
      }
    },
    moveDropdownToBody(ref) {
      this.$nextTick(() => {
        const dropdownMenu = this.$refs[ref].$el.querySelector('.dropdown-menu');
        if (dropdownMenu && !dropdownMenu.dataset.moved) {
        document.body.appendChild(dropdownMenu);
        dropdownMenu.dataset.moved = true;
      }
      });
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      STATUS_COLOR,
      total, 
      perPage, 
      page
    };
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-good-table.scss';
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
.bulk-selection-wrapper {
  background: none !important;
  font-size: 14px;
  color: inherit !important;
}
::v-deep .vgt-selection-info-row {
  background: none;
  font-size: 14px;
  color: inherit;
}
</style>
<style lang="scss" scoped>
.action-menu {
  display: inline-block;
}

</style>
