import { GOODTABLE_ISO_DATETIME_INPUT_FORMAT, GOODTABLE_LOCALISED_DATE_FORMAT } from "@/libs/utils";
import { userStatusSelect } from "../userStatus";

export const mentoringProgramParticipantColumns = [
  {
    label: "Full Name",
    field: "full_name",
    filterOptions: {
      enabled: true,
      placeholder: "Full Name",
      filterValue: '',
    },
    width: "12em",
  },
  {
    label: "Email",
    field: "email",
    filterOptions: {
      enabled: true,
      placeholder: "Email",
      filterValue: '',
    },
    width: "14em",
  },
  {
    label: "Role",
    field: "role",
    filterOptions: {
      enabled: true,
      placeholder: "All",
      filterValue: ''
    },
    width: "4em",
  },
  {
    label: "Matches",
    field: "locked_and_active_matches_count",
    filterOptions: {
      enabled: true,
      placeholder: "Matches",
      filterValue: '',
    },
    width: "7em",
  },
  {
    label: "Date Added",
    field: "created_at",
    filterOptions: {
      enabled: true,
      placeholder: "Date Added",
      filterValue: '',
    },
    width: "9em",
    type: 'date',
    dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
    dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
    thClass: 'vgt-left-align',
    tdClass: "vgt-left-align",
  },
  {
    label: "Status",
    field: 'currentStatus',
    filterOptions: {
      enabled: true,
      placeholder: "All",
      filterDropdownItems: userStatusSelect,
      filterValue: ''
    },
    width: "6em",
  },
  {
    label: "Email status",
    field: 'email_notification_status',
    filterOptions: {
      enabled: false,
    },
    sortable: false,
    width: "9em",
  },
  {
    field: 'action',
    sortable: false,
    width: "10em"
  }
];
export const trainingProgramParticipantColumns = [
  {
    label: "Full Name",
    field: "full_name",
    filterOptions: {
      enabled: true,
      placeholder: "Full Name",
      filterValue: '',
    },
    width: "12em",
  },
  {
    label: "Email",
    field: "email",
    filterOptions: {
      enabled: true,
      placeholder: "Email",
      filterValue: '',
    },
    width: "14em",
  },
  {
    label: "Allocated courses",
    field: "allocated_courses",
    width: "14em",
    sortable: false,
  },
  {
    label: "Date Added",
    field: "created_at",
    filterOptions: {
      enabled: true,
      placeholder: "Date Added",
      filterValue: '',
    },
    width: "9em",
    type: 'date',
    dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
    dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
    thClass: 'vgt-left-align',
    tdClass: "vgt-left-align",
  },
  {
    label: "Status",
    field: 'currentStatus',
    filterOptions: {
      enabled: true,
      placeholder: "All",
      filterDropdownItems: userStatusSelect,
      filterValue: ''
    },
    width: "6em",
  },
  {
    label: "Email status",
    field: 'email_notification_status',
    filterOptions: {
      enabled: false,
    },
    sortable: false,
    width: "9em",
  },
  {
    field: 'action',
    sortable: false,
    width: "10em"
  }
];