<!-- eslint-disable vue/no-v-html -->
<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col class="text-right">
            <action-button
              v-if="!isProgramTypeTraining"
              variant="outline-primary"
              class="btn-icon"
              @click="showBulkImportModal"
            >
              <feather-icon
                icon="UploadIcon"
                size="16"
                class="mr-50"
              /> 
              Bulk Import
            </action-button>
            <action-button
              v-if="!isProgramTypeTraining"
              @click="showBulkExportModal"
              variant="outline-primary"
              class="btn-icon ml-1">
              <feather-icon
                icon="DownloadIcon"
                size="16"
                class="mr-50"
              /> 
              Export
            </action-button>
            
            <action-button
              class="add-participant-btn ml-1"
              variant="primary"
              @click="openAddSideBarToggle"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              /> 
              <span class="text-nowrap">Add Participant</span>
            </action-button>
          </b-col>
        </b-row>
        <participant-list
          ref="participantList"
          @editParticipant="onParticipantEdit"
        />
      </b-card>
      <b-sidebar
        id="add-participant"
        v-model="openAddSideBar"
        title="Add Participant"
        bg-variant="white"
        right
        shadow
        backdrop
        width="50rem"
      >
        <participant-add
          ref="addParticipant"
          @userAdded="clearAddForm"
          @close="clearAddForm"
        />
      </b-sidebar>
      <b-sidebar
        id="edit-participant"
        v-model="openEditSideBar"
        title="Edit Participant"
        bg-variant="white"
        aria-controls="edit-participant-sidebar"
        :aria-expanded="openEditSideBar"
        right
        shadow
        backdrop
        width="50rem"
      >
        <participant-edit
          ref="editParticipant"
          :participant-id="participantId"
          @userUpdated="clearEditForm"
          @close="clearEditForm"
        />
      </b-sidebar>
    </b-col>

    <b-modal
      v-model="showImportModal"
      :title="'Bulk Import'"
      centered
    >
      <b-alert
        class="message-error mb-2 px-2 py-1"
        variant="danger"
        :show="!!importError"
      >
        <p
          class="font-weight-normal"
          v-html="importError"
        />
      </b-alert>
      <b-link
        class="text-primary"
        href="/import_participants.csv"
      >
        Download template
      </b-link>

      <validation-observer
        ref="observer"
      >
        <validation-provider
          v-slot="validationContext"
          ref="file"
          :rules="{ required: true }"
          name="File"
        >
          <b-form-file
            ref="refInputEl"
            v-model="blogFile"
            :state="
              getValidationState(
                validationContext
              )
            "
            class="mt-3"
            placeholder="Add file"
            accept=".csv"
            drop-placeholder="Drop file here..."
            type="file"
            plain
          />
          <b-form-invalid-feedback
            :state="
              getValidationState(
                validationContext
              )
            "
          >
            {{
              validationContext.errors[0]
            }}
          </b-form-invalid-feedback>
        </validation-provider>
      </validation-observer>
      <!-- Modal Footer -->
      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            right
            @click="onImportParticipant"
          >
            <b-spinner
              v-if="isImporting"
              style="margin-right: 5px"
              small
            />
            <span v-if="isImporting">Importing...</span>
            <span v-else>Import</span>
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="showExportModal"
      :title="'Export'"
      centered
    >
    <div class="d-flex justify-content-center align-items-center pt-4 pb-4">
        <feather-icon
          v-b-tooltip.hover.top="'Exported data includes participant matches, including active, completed & cancelled matches.'"
          icon="InfoIcon"
          size="16"

        /> 

        <b-form-checkbox
          v-model="exportWithMatches"
          value="accepted"
          unchecked-value=""
          class="ml-2"
        >
        Export matches
        </b-form-checkbox>  
    </div>

      <!-- Modal Footer -->
      <template #modal-footer>
        <div class="d-flex w-100 justify-content-center">
          <b-button
            variant="primary"
            centered
           class="mr-2"
            @click="onDownloadParticipantList('csv')"
          >
            <span >Export as CSV</span>
          </b-button>

          <b-button
            variant="primary"
            right
            @click="onDownloadParticipantList('xlsx')"
          >
            <span>Export as XLSX</span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  VBToggle,
  VBTooltip,
  BSidebar,
  BDropdown,
  BDropdownItem,
  BModal,
  BLink,
  BFormFile,
  BFormInvalidFeedback,
  BSpinner,
  BAlert,
  BFormCheckbox
} from "bootstrap-vue";
import ParticipantList from "./ParticipantList.vue";
import ParticipantAdd from "./ParticipantAdd.vue";
import ParticipantEdit from "./ParticipantEdit.vue";
import { BE_API_URL } from "@/constants/app";
import participantsService from "@/services/participantsService";
import { makeSuccessToast, makeErrorToast, convertModelToFormData } from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { getValidationState } from "@/libs/utils";
import ActionButton from "@/views/components/common/ActionButton.vue";
import { mapGetters } from 'vuex';
import { programTypes } from "@/models";

export default {
  components: {
    ActionButton,
    BRow,
    BCol,
    BButton,
    BCard,
    BButton,
    BSidebar,
    BFormCheckbox,
    ParticipantList,
    ParticipantAdd,
    ParticipantEdit,
    BDropdown,
    BDropdownItem,
    BModal,
    BLink,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BSpinner,
    BAlert
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      required,
      showImportModal: false,
      showExportModal: false,
      participantId: undefined,
      openEditSideBar: false,
      openAddSideBar: false,
      blogFile: null,
      isImporting: false,
      importError: null,
      exportWithMatches: false
    };
  },
  computed:{
    ...mapGetters('programs',['defaultProgram']),
    isProgramTypeTraining() {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
  },
  methods: {
    clearAddForm() {
      this.$refs.addParticipant.clearForm();
      this.participantId = null;
      this.openAddSideBar = false;
      this.$refs.participantList.loadItems();
    },
    clearEditForm() {
      this.$refs.editParticipant.clearForm();
      this.openEditSideBar = false;
      this.participantId = null;
      this.$refs.participantList.loadItems();
    },
    onParticipantEdit(id) {
      this.participantId = id;
      this.openEditSideBar = true;
    },
    openAddSideBarToggle() {
      this.openAddSideBar = true;
    },
    onDownloadParticipantList(type) {
       const dowloadLink = `${BE_API_URL}/programs/${this.$route.params.id}/users/participants/download/${type}?with_participant_candidates=1&with_participant_matches=${this.exportWithMatches ? 1 : 0}`;
       window.document.location = dowloadLink;
       this.hideBulkExportModal();
     },

    showBulkImportModal() {
      this.showImportModal = true;
      this.importError = null;
    },
    hideBulkImportModal() {
      this.showImportModal = false;
    },
    showBulkExportModal() {
      this.showExportModal = true;
    },
    hideBulkExportModal() {
      this.showExportModal = false;
    },
    showBulkExportModal() {
      this.showExportModal = true;
    },
    hideBulkExportModal() {
      this.showExportModal = false;
    },
    async onImportParticipant() {
      this.$refs.observer.validate().then(async valid => {
        if(!valid) return;
        try {
          this.isImporting = true;
          const programId = this.$route.params.id;
          await participantsService.importParticipants(
            programId,
            convertModelToFormData({file: this.blogFile})
          );
          this.$toast(makeSuccessToast("Participants were imported successfully."));
          this.hideBulkImportModal();
        } catch(e) {
          const { status, data } = e.response;
          if (status === 422 && data.errors) {
            const formatMessage = mess => {
              const words = mess.split(' ');
              words[0] = "Row";
              words[1] = words[1].replace(/\.|_/g, ' ');
              return words.join(" ");
            };
            const messages = [];
            Object.keys(data.errors).map((key, index) => {
              if (data.errors[key] && index <= 5) {
                messages.push(formatMessage(data.errors[key][0]));
              }
            });
            this.importError = messages.join("<br/>");
            this.$refs.observer.setErrors(data.errors);
          } else {
            this.$toast(makeErrorToast(data.message));
          }
        } finally {
          this.isImporting = false;
        }
      });
    }
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>

<style lang="scss">
#add-participant___title__ {
  font-size: 18px;
  font-weight: 500;
  flex:2;
}

.b-sidebar > .b-sidebar-header {
  display: flex;
  flex-direction: row-reverse;
}
$sidebar-header-text-color: #808080;
  .b-sidebar > .b-sidebar-header{
    flex-direction: row-reverse;
    background: #f3f3f3;
    color: $sidebar-header-text-color;

    #edit-participant___title__ {
      font-size: .8em;
      flex: 2;
    }
  }
</style>